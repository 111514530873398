<template>
	<v-container fluid>
		<!-- Create/Update Modal -->
		<v-dialog v-model="dialog" persistent max-width="600px">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="primary" small v-bind="attrs" v-on="on"
					><v-icon small> mdi-plus </v-icon>{{ $t('add') }}</v-btn
				>
			</template>
			<v-card>
				<v-card-title>
					<span class="headline">{{ formTitle }}</span>
				</v-card-title>

				<v-card-text>
					<v-form ref="form" lazy-validation>
						<!-- Alergies -->
						<v-text-field
							id="alergies"
							:label="$t('alergy') + '*'"
							name="alergies"
							type="text"
							v-model="editedItem.alergies"
							:rules="alergiesRules"
							required
						></v-text-field>

						<!-- Notes -->
						<v-textarea
							id="notes"
							:label="$t('notes')"
							name="notes"
							type="text"
							v-model="editedItem.notes"
							rows="2"
							auto-grow
						></v-textarea>
					</v-form>

					<!-- Loader -->
					<div v-if="isLoading" class="text-center">
						<v-progress-circular
							indeterminate
							color="primary"
						></v-progress-circular>
					</div>
					<small>*{{ $t('requiredFields') }}</small>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="grey" dark @click="close">
						{{ $t('close') }}
					</v-btn>
					<v-btn color="primary" @click="save">
						{{ $t('save') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Delete Modal -->
		<DeleteModal
			:dialogDelete="dialogDelete"
			:title="$t('confirmDelete')"
			@cancel="closeDelete"
			@ok="deleteConfirm"
		/>

		<v-data-table
			v-if="pet"
			:headers="headers"
			:items="pet.alergies"
			:footer-props="footerProps"
			multi-sort
			class="mt-3"
		>
			<template v-slot:item.date="{ item }">
				<span>{{ formatDate(item.date) }}</span>
			</template>

			<template v-slot:item.edit="{ item }">
				<v-icon @click="editItem(item)">
					mdi-pencil
				</v-icon>
			</template>
			<template v-slot:item.delete="{ item }">
				<v-icon @click="deleteItem(item)">
					mdi-delete
				</v-icon>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
	name: 'Vaccines',
	components: {
		DeleteModal: () => import('@/components/DeleteModal'),
	},

	data() {
		return {
			pet: null,
			isLoading: false,
			dialog: false,
			dialogDelete: false,
			editedIndex: -1,
			editedItem: {
				condition: '',
				treatment: '',
			},
			defaultItem: {
				condition: '',
				treatment: '',
			},
			alergiesRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	computed: {
		headers() {
			return [
				{ text: this.$t('alergy'), align: 'start', value: 'alergies' },
				{ text: this.$t('openEdit'), value: 'edit' },
				{ text: this.$t('delete'), value: 'delete' },
			];
		},
		footerProps() {
			return { 'items-per-page-text': this.$t('rowsPerPage') };
		},
		formTitle() {
			return this.editedIndex === -1 ? this.$t('add') : this.$t('edit');
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},
	methods: {
		...mapActions('pets', ['getPet', 'editPet']),
		formatDate: date => (date = moment(date).format('DD.MM.YYYY')),
		editItem(item) {
			this.editedIndex = this.pet.alergies.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		deleteItem(item) {
			this.editedIndex = this.pet.alergies.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},

		deleteConfirm() {
			this.pet.alergies.splice(this.editedIndex, 1);
			this.callEditPet();
			this.closeDelete();
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},

		save() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			if (this.editedIndex > -1) {
				Object.assign(this.pet.alergies[this.editedIndex], this.editedItem);
			} else {
				this.pet.alergies.unshift(this.editedItem);
			}
			this.callEditPet();
			this.close();
		},
		async callEditPet() {
			this.isLoading = true;

			try {
				const res = await this.editPet(this.pet);
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.isLoading = false;
			}
		},
	},
	async mounted() {
		this.isLoading = true;

		try {
			const res = await this.getPet(this.$route.params.id);

			this.pet = res.data.data;
			this.isLoading = false;
		} catch (error) {
			console.error(error);
			this.isLoading = false;
		}
	},
};
</script>

<style lang="scss" scoped></style>
